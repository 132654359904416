import { createContext, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import callApi from '../utils/callApi';
import { auth, logout } from "../utils/firebase";
import { buildUserPayload } from "../utils/helpers";

export const AppContext = createContext({})

const GlobalState = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  const [errors, setErrors] = useState([]);
  const [role, setRole] = useState('');
	const [collections, setCollections] = useState([]);
	const [ownedItems, setOwnedItems] = useState([]);

	async function readSession() {
    const user = await window.sessionStorage.getItem(
      `firebase:authUser:${process.env.REACT_APP_API_KEY}:[DEFAULT]`
    );
    if (user) {
			const storedUser = await localStorage.getItem('user');
			storedUser && !isEmpty(storedUser) && setUser(JSON.parse(storedUser));
      setLoggedIn(true);
    }
  };

	useEffect(() => {
		try {
			readSession();
			auth.onAuthStateChanged(async firebaseUser => {
				try {
					const userFromRedirect = await auth.getRedirectResult()
						.catch(err => {
							setErrors([err?.message]);
						});
					if (!isEmpty(userFromRedirect?.user)) {
						await handleSignUp(userFromRedirect);
					} else if (!isEmpty(firebaseUser)) {
						await handleSignUp({ user: firebaseUser });
					} 

					if (!firebaseUser) {
						await localStorage.clear();
						setUser({});
						setLoggedIn(false);
					}
				} catch (error) {
					console.log('onAuthStateChanged', error)
				}
			})
		} catch (error) {
			console.error('Authentication', error);
		}
	}, []); // eslint-disable-line

	useEffect(() => {
		fetchCollections();
	}, []); // eslint-disable-line

	useEffect(() => {
		user?.uid && loadOwnedCollections();
	}, [user?.uid]); // eslint-disable-line

	const handleSignUp = async (userData, params = {}) => {
		try {
			const role = await localStorage.getItem('role');

			if (!isEmpty(userData?.user)) {
				let payload;
				if (userData?.additionalUserInfo) {
					payload = buildUserPayload({ 
						userData, role, ...params
					});
				} else {
					const storedUser = await localStorage.getItem('user');
					if (storedUser && !isEmpty(storedUser)) {
						payload = storedUser && JSON.parse(storedUser);
					}
				}

				let idTokenResult;
				if (userData?.additionalUserInfo?.isNewUser) {
					const userResult = await callApi('put', 'user', payload);
					userResult?.status !== 'success' && setErrors([...errors, userResult?.error]);
					payload = userResult?.userFirebase;
				} else {
					idTokenResult = await auth?.currentUser?.getIdTokenResult();					
					const roleFromClaims = idTokenResult?.claims?.role;

					if (payload && !isEmpty(payload) && roleFromClaims) {
						await localStorage.setItem('role', roleFromClaims);
						setRole(roleFromClaims);
						payload.role = roleFromClaims;
						payload.signInProvider = idTokenResult?.signInProvider;
					}
				}
				payload && !isEmpty(payload) && await localStorage.setItem('user', JSON.stringify(payload));
				setUser(payload);
				checkStoredUser(payload);
			}
			setLoggedIn(!isEmpty(userData?.user));
		} catch (error) {
			console.error(error);
		}
	}

	const checkStoredUser = async (payload) => {
		try {
			let storedUserRecordResponse = await callApi('get', 'user');
			let storedUserRecord = storedUserRecordResponse?.userFirebase;
			if (payload && !isEmpty(payload) && !storedUserRecord) {
				const userResult = await callApi('put', 'user', payload);
				userResult?.status !== 'success' && setErrors([...errors, userResult?.error]);
				storedUserRecord = userResult?.userFirebase;
			}

			const userObj = { ...payload, ...storedUserRecordResponse?.user, ...storedUserRecordResponse?.userFirebase };
			setUser(userObj);
			userObj && !isEmpty(userObj) && await localStorage.setItem('user', JSON.stringify(userObj));
		} catch (error) {
			console.error(error);
		} 
	}

	const userLogout = async () => {
		logout();
		setLoggedIn(false);
		setUser({});
		setErrors([]);
		setRole('');
	}

	async function fetchCollections() {
		try {
			const response = await callApi('get', 'public');
			if (!response?.error && response?.status !== 'error') {
				response?.result && setCollections(response?.result);
			} else {
				console.error('Error loading collections', response?.error);
			}
		} catch (err) {
			console.error('Error while loading collections data', err);
		}
	}

		async function loadOwnedCollections() {		
			try {
				const response = await callApi('get', 'owned');
				if (!response?.error && response?.status !== 'error') {
					response?.items && setOwnedItems(response?.items);
				} else {
					console.error('Error loading owned collections', response?.error);
				}
			} catch (err) {
				console.error('Error while loading owned collections', err);
			}
		}

	return (
		<AppContext.Provider value={{
			isLoggedIn, setLoggedIn,
			user, setUser,
			role, setRole,
			errors, setErrors,
			handleSignUp, userLogout,
			collections,
			ownedItems
		}}>
			{children}
		</AppContext.Provider>
	);
};

export default GlobalState;