import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Menu, Dropdown } from 'antd';
import { UserOutlined, SearchOutlined } from '@ant-design/icons';
import { AppContext } from '../context/GlobalState';
import { tintLogo } from '.';

const Navbar = ({ callback, search, login }) => {
	const navigate = useNavigate();
	const { isLoggedIn, user, userLogout } = useContext(AppContext);

	const onLogout = async () => {
		userLogout();
		navigate('/register');
	};

	const handleMenuClick = e => {
		switch (e.key) {
			case 'profile':
				navigate(`/profile`);
				break;
			case 'profile-settings':
				navigate(`/settings`);
				break;
			case 'log-out':
				onLogout();
				break;
			default:
				break;
		}
	};

	const userMenu = (
		<Menu className='avatar-menu' onClick={handleMenuClick}>
			<Menu.Item key='profile' className='avatar-menu-item'>
				Profile
			</Menu.Item>
			<Menu.Item key='log-out' className='avatar-menu-item'>
				Log out
			</Menu.Item>
		</Menu>
	);

	return (
		<div className='header-wrapper'>
			<Link to={'/home'} className='title'>
				<img alt='Tint' src={tintLogo} />
			</Link>
			<div className='search-box-wrapper'>
				{search ? (
					<div className='input-search'>
						<input
							id='search-box'
							onChange={e => {
								callback(e.target.value);
							}}
							placeholder='Search this page'
							type='text'
							className='search-box'
						/>
						<label htmlFor='search-box'>
							<SearchOutlined className='search-icon' />
						</label>
					</div>
				) : null}
			</div>
			{!login && isLoggedIn && user?.uid ? (
				<div className='options-wrapper'>
					<Link to={'/claim'}>
						<button className='primary-btn'>Claim NFT</button>
					</Link>
					<Dropdown
						className='avatar-dropdown'
						overlay={userMenu}
						placement='bottomRight'>
						<Avatar src={user?.photoURL} icon={<UserOutlined />} />
					</Dropdown>
				</div>
			) : (
				<div className='options-wrapper'>
					<Link to={'/claim'}>
						<button className='primary-btn'>Claim NFT</button>
					</Link>
					<Link to={'/register'}>Sign in</Link>
				</div>
			)}
		</div>
	);
};

export default Navbar;
