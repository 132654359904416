import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input } from 'antd';
import { Loading } from '.';
import { AppContext } from '../context/GlobalState';
import { signInWithCredentials } from '../utils/firebase';

const RegisterForm = ({ callback }) => {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [loading, setLoading] = useState(false);
	const { setErrors, handleSignUp } = useContext(AppContext);

	const onFinishErrorCallback = async result => {
		setErrors([result]);
		setLoading(false);
	};

	const onRegister = async values => {
		try {
			setLoading(true);
			signInWithCredentials(
				'createUser',
				{ email: values.email, password: values.password },
				onFinishCallback,
				onFinishErrorCallback
			);
			const claimedItem = await JSON.parse(localStorage.getItem('claim'));
			claimedItem
				? navigate(`/nft/${claimedItem.collectionId}/${claimedItem.itemId}`, {
						state: claimedItem
				  })
				: navigate('/home');
		} catch (error) {
			console.error(error.message);
		}
	};

	const onFinishCallback = async (result, params) => {
		await handleSignUp(result, { ...params, firstName, lastName });
	};

	return (
		<div className='login-wrapper'>
			{loading ? (
				<Loading />
			) : (
				<div className='login-box'>
					<span className='header'>Join TINT</span>
					<Form
						form={form}
						size='large'
						layout='vertical'
						name='register-form'
						hideRequiredMark
						onFinish={onRegister}
						autoComplete='off'
						onKeyDown={e => (e.key === 'Enter' ? e.preventDefault() : '')}
						validateTrigger='onSubmit'>
						<Form.Item
							name='firstName'
							label='First Name'
							required
							hasFeedback
							rules={[
								{
									required: true,
									message: 'Required field!'
								}
							]}>
							<Input onChange={e => setFirstName(e.target.value)} />
						</Form.Item>
						<Form.Item
							name='lastName'
							label='Last name'
							required
							hasFeedback
							rules={[
								{
									required: true,
									message: 'Required field!'
								}
							]}>
							<Input onChange={e => setLastName(e.target.value)} />
						</Form.Item>
						<Form.Item
							name='email'
							label='Email'
							required
							hasFeedback
							rules={[
								{
									required: true,
									message: 'Please input your email!'
								}
							]}>
							<Input />
						</Form.Item>
						<Form.Item
							name='password'
							label='Password'
							required
							hasFeedback
							rules={[
								{
									required: true,
									message: 'Please input your password!'
								},
								{
									min: 8,
									max: 32,
									message: 'Password should have at least 8 characters'
								}
							]}>
							<Input.Password />
						</Form.Item>
						<button className='primary-btn' type='submit'>
							Sign Up
						</button>
					</Form>
					<button onClick={() => callback(false)} className='tertiary-btn'>
						Back to all sign up options
					</button>
				</div>
			)}
		</div>
	);
};

export default RegisterForm;
