import React, { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Space, Avatar, Menu, Image } from 'antd';
import callApi from '../utils/callApi';
import { dateOptions } from '../utils/helpers';
import { Layout, ConfirmModal, missingImage } from '../components';
import { AppContext } from '../context/GlobalState';

const { SubMenu } = Menu;

const Item = () => {
	const { state } = useLocation();
	const navigate = useNavigate();
	const [confirmVisible, setConfirmVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [err, setErr] = useState(false);
	const [modalMessage, setModalMessage] = useState('');
	const { user, isLoggedIn } = useContext(AppContext);

	const onClaimItem = async () => {
		try {
			if (isLoggedIn && user?.uid) {
				setLoading(true);
				setConfirmVisible(true);
				const response = await callApi('put', 'owned', {
					referenceCode: `${state?.collectionId}/${state?.itemId}/${state?.claimCode}`
				});
				if (!response?.error && response?.status !== 'error') {
					setLoading(false);
					setModalMessage(response?.message);
					navigate(`/profile`);
				} else {
					setLoading(false);
					setErr(true);
					setModalMessage(response?.message);
					setConfirmVisible(true);
					console.error('Error claiming item', response);
				}
			} else {
				setLoading(false);
				setErr(true);
				setModalMessage('Please sign in to add NFT to collection!');
				setConfirmVisible(true);
			}
		} catch (err) {
			setLoading(false);
			setErr(true);
			setConfirmVisible(true);
			console.error('Error while claiming item', err);
		}
	};

	const onClaimFinish = () => {
		if (err && !isLoggedIn && !user?.uid) {
			navigate(`/register`);
		}
		setConfirmVisible(false);
	};

	return (
		<Layout>
			<div className='nft-view-wrapper'>
				<div className='nft-wrapper'>
					<div className='nft-box-wrapper'>
						<Image src={state?.imageUrl} alt={state?.itemName} fallback={missingImage} />
					</div>
					<Space size='middle' direction='vertical' align='start'>
						<span className='title bold'>{`About NFT ${state?.itemName}`}</span>
						<span className='description'>{state?.itemDescription}</span>
					</Space>
				</div>
				<div className='nft-content-wrapper'>
					<span className='title'>{`NFT ${state?.itemName}`}</span>
					<Space size='middle'>
						<Avatar src={state?.collectionLogoUrl} size={70} />
						<Space size={2} direction='vertical' align='start'>
							<span className='title-sm bold'>{state?.collectionName}</span>
							<span className='owner bold'>{`by ${state?.clientName}`}</span>
						</Space>
					</Space>
					<Space className='info' direction='vertical' align='start'>
						<span className='label bold'>Rarity</span>
						<div className='box'>
							<span className='data'>1/1000</span>
						</div>
					</Space>
					<Space className='info' direction='vertical' align='start'>
						<span className='label bold'>Expiration</span>
						<div className='box'>
							{new Date(Number(state?.endDate)) >= new Date() ? (
								<span className='data'>
									{new Date(Number(state?.endDate)).toLocaleString('en-us', dateOptions)}
								</span>
							) : (
								<span className='data'> Ended </span>
							)}
						</div>
					</Space>
					<Space className='btns-wrapper' direction='vertical' size='middle'>
						<button onClick={() => onClaimItem()} className='primary-btn'>
							Add NFT
						</button>
						<button onClick={() => {}} className='secondary-btn'>
							Show QR-Code
						</button>
						<button onClick={() => {}} className='secondary-btn'>
							Share
						</button>
					</Space>
					<Menu mode='inline'>
						<SubMenu key='details' title='Details'>
							{state?.claimedDate && (
								<Menu.Item key='claimed'>
									<span className='label'>Claimed</span>
									<span className='data'>
										{new Date(Number(state?.claimedDate)).toLocaleString(
											'en-us',
											dateOptions
										)}
									</span>
								</Menu.Item>
							)}
							{state?.contract?.network && (
								<Menu.Item key='network'>
									<span className='label'>Network</span>
									<span className='data'>{state?.contract?.network}</span>
								</Menu.Item>
							)}
							{state?.contract?.address && (
								<Menu.Item key='contract'>
									<span className='label'>Contract</span>
									<span className='data'>{state?.contract?.address}</span>
								</Menu.Item>
							)}
						</SubMenu>
					</Menu>
				</div>
				<ConfirmModal
					err={err}
					message={modalMessage}
					visible={confirmVisible}
					onFinish={onClaimFinish}
					itemId={state?.itemId}
					loading={loading}
				/>
			</div>
		</Layout>
	);
};

export default Item;
