import React, { useEffect, useState, useContext, Fragment } from 'react';
import { Space, Tabs, Avatar, Select } from 'antd';
import { UserOutlined, SearchOutlined } from '@ant-design/icons';
import { AppContext } from '../context/GlobalState';
import { Layout, NftCard, CampaignBox, Loading } from '../components';

const { TabPane } = Tabs;
const { Option } = Select;

const Profile = () => {
	const [currentCollections, setCurrentCollections] = useState([]);
	const [currentItems, setCurrentItems] = useState([]);
	const [filterValue, setFilterValue] = useState();
	const [searchQuery, setSearchQuery] = useState('');
	const [loading, setLoading] = useState(false);
	const [sortValue, setSortValue] = useState('startDate');
	const { user, ownedItems, collections } = useContext(AppContext);

	useEffect(() => {
		setLoading(true);
		let filteredItems = ownedItems;
		let filteredCollections = collections;

		if (searchQuery) {
			filteredItems = ownedItems?.filter(
				item =>
					item?.collectionName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
					item?.collectionId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
					item?.collectionDescription
						?.toLowerCase()
						.includes(searchQuery.toLowerCase()) ||
					item?.collectionType?.toLowerCase().includes(searchQuery.toLowerCase()) ||
					item?.clientName?.toLowerCase().includes(searchQuery.toLowerCase())
			);
			filteredCollections = collections?.filter(
				collection =>
					collection?.collectionName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
					collection?.collectionId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
					collection?.collectionDescription
						?.toLowerCase()
						.includes(searchQuery.toLowerCase()) ||
					collection?.collectionType?.toLowerCase().includes(searchQuery.toLowerCase()) ||
					collection?.clientName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
					collection?.items?.filter(
						item =>
							item?.itemName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
							item?.itemDescription?.toLowerCase().includes(searchQuery.toLowerCase()) ||
							item?.itemId?.toLowerCase().includes(searchQuery.toLowerCase())
					)?.length
			);
		}

		switch (sortValue) {
			case 'collectionName':
				filteredCollections = filteredCollections?.sort((a, b) =>
					a?.collectionName.localeCompare(b?.collectionName)
				);
				break;
			case 'clientName':
				filteredItems = filteredItems?.sort((a, b) =>
					a?.collectionName.localeCompare(b?.collectionName)
				);
				break;
			case 'startDate':
			default:
				filteredCollections = filteredCollections?.sort(
					(a, b) => Number(a?.startDate) - Number(b?.startDate)
				);
				break;
		}

		setCurrentItems(filteredItems?.filter(item => Number(item?.startDate) <= Date.now()));
		setCurrentCollections(
			filteredCollections?.filter(
				collection => Number(collection?.startDate) <= Date.now()
			)
		);
		setLoading(false);
	}, [ownedItems, collections, searchQuery, filterValue, sortValue]);

	const handleChange = value => {
		setFilterValue(value);
	};

	const ELEMENT_FILTER = {
		undefined: element => element,
		active: element => Number(element?.endDate) >= Date.now(),
		expired: element => Number(element?.endDate) < Date.now()
	};

	return (
		<Layout>
			{loading ? (
				<Loading />
			) : (
				<Fragment>
					<div className='sub-header-profile'>
						<Space size='large' align='center'>
							<Avatar
								src={user?.photoURL}
								icon={<UserOutlined style={{ fontSize: '80px' }} />}
								size={134}
							/>
							<span className='username'>{user?.displayName}</span>
						</Space>
					</div>
					<div className='campaigns-tab-wrapper'>
						<Tabs centered defaultActiveKey='collections'>
							<div className='options-wrapper'>
								<Select
									showArrow={false}
									className='selector-wrapper'
									placeholder='Status'
									onChange={handleChange}>
									<Option className='tertiary-btn' value='active'>
										Active
									</Option>
									<Option className='tertiary-btn' value='expired'>
										Expired
									</Option>
								</Select>
								<div className='search-box-wrapper'>
									<div className='input-search'>
										<input
											id='search-box'
											onChange={e => setSearchQuery(e.target.value)}
											placeholder='Search this page'
											type='text'
											className='search-box'
										/>
										<label htmlFor='search-box'>
											<SearchOutlined className='search-icon' />
										</label>
									</div>
								</div>
								<Select
									style={{ width: 260 }}
									placeholder='Sort by'
									onChange={setSortValue}>
									<Option value='startDate'>Starting date</Option>
									<Option value='clientName'>Client name</Option>
									<Option value='collectionName'>Campaign name</Option>
								</Select>
							</div>
							<TabPane tab='Collections' key='collections'>
								{currentCollections
									?.filter(ELEMENT_FILTER[filterValue])
									.map(collection => (
										<CampaignBox
											key={collection?.collectionId}
											collection={collection}
											ownedItems={ownedItems}
										/>
									))}
							</TabPane>
							<TabPane tab='All Items' key='all-items'>
								{currentItems?.map(item => (
									<NftCard
										key={item?.itemId}
										imageUrl={item?.imageUrl}
										itemName={item?.itemName}
										collectionName={item?.collectionName}
										collectionLogoUrl={item?.collectionLogoUrl}
									/>
								))}
							</TabPane>
						</Tabs>
					</div>
				</Fragment>
			)}
		</Layout>
	);
};

export default Profile;
