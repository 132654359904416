import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, LoginForm } from '../components';
import { AppContext } from '../context/GlobalState';

const Login = () => {
	const navigate = useNavigate();
	const { isLoggedIn } = useContext(AppContext);

	useEffect(() => {
		isLoggedIn && navigate('/home');
	}, [isLoggedIn]); // eslint-disable-line

	return (
		<Layout login>
			<LoginForm />
		</Layout>
	);
};

export default Login;
