import React from 'react';
import { useLocation } from 'react-router-dom';
import { Space, Avatar } from 'antd';
import Icon from '@ant-design/icons';
import { dateOptions } from '../utils/helpers';
import {
	Layout,
	WebIconOutlined,
	TwitterIconOutlined,
	InstagramIconOutlined,
	NftCard,
} from '../components';

const Collection = () => {
	const { state } = useLocation();

	return (
		<Layout>
			<div className='sub-header-campaign'>
				<img
					alt={`${state?.collectionName}-blur`}
					src={state?.collectionBannerUrl}
					className='banner-blur-bg'
				/>
				<img
					alt={state?.collectionName}
					src={state?.collectionBannerUrl}
					className="campaign-banner-image"
				/>
			</div>
			<div className='campaign-page-wrapper'>
				<div className='campaign-info-wrapper'>
					<Avatar
						className='avatar-wrapper'
						src={state?.collectionLogoUrl}
						size={155}
					/>
					<div className='info-2'>
						<div className='info-inner'>
							<Space size={10} direction='vertical'>
								<span className='collection-name bold'>
									{state?.collectionName}
								</span>
								<div className='client-name bold'>by {state?.clientName}</div>
							</Space>
							<Space size='large'>
								<div className='date-wrapper'>
									{new Date(Number(state?.endDate)) > new Date() ? (
										<Space>
											<span className='info'>Ending</span>
											<span className='date'>
												{new Date(Number(state?.endDate)).toLocaleString(
													'en-us',
													dateOptions
												)}
											</span>
										</Space>
									) : (
										<span className='info'>Ended</span>
									)}
								</div>
								<Space size='middle'>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href={state?.websiteUrl}
                  >
										<Icon component={() => <WebIconOutlined />} />
									</a>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href={state?.twitterUrl}
                  >
										<Icon component={() => <TwitterIconOutlined />} />
									</a>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href={state?.instagramUrl}
                  >
										<Icon component={() => <InstagramIconOutlined />} />
									</a>
								</Space>
							</Space>
						</div>
						<div className='description'>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus enim
							magna at facilisis ipsum aliquam neque. Lorem ipsum dolor sit amet,
							consectetur adipiscing elit. Faucibus enim magna at facilisis ipsum
							aliquam neque.
						</div>
					</div>
				</div>
				<div className='campaign-content-wrapper'>
					<div className='header'>Latest NFTs</div>
					<div className='items-wrapper'>
						{state?.items?.map(item => (
							<NftCard
								key={item?.itemId}
								imageUrl={item?.imageUrl}
								itemName={item?.itemName}
								collectionName={state?.collectionName}
								collectionLogoUrl={state?.collectionLogoUrl}
								endDate={state?.endDate}
								claimed={item?.isClaimed}
							/>
						))}
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Collection;
