import React, { Fragment } from 'react';
import { Modal } from 'antd';
import { tickIcon, errIcon, Loading } from '.';

const ConfirmModal = ({ visible, onFinish, err, message, itemId, loading = true }) => (
	<Modal
		visible={visible}
		okText={!err ? 'Finish' : 'Try again'}
		cancelButtonProps={{ style: { display: 'none' } }}
		okButtonProps={
			loading ? { style: { display: 'none' } } : { className: 'primary-btn' }
		}
		closable={false}
		onOk={onFinish}
		className='confirm-modal'>
		<div className='confirm-content-wrapper'>
			{loading ? (
				<Loading />
			) : (
				<Fragment>
					<img src={!err ? tickIcon : errIcon} alt='claim-status' />
					<div className='confirm-info'>
						{!err ? `NFT #${itemId} was successfully added!` : `${message}!`}
					</div>
				</Fragment>
			)}
		</div>
	</Modal>
);

export default ConfirmModal;
