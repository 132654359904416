import axios from "axios";
import { auth } from "./firebase";
import config from "../config.json";

const callApi = async (mode, url, payload, customHeaders = null) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      ...customHeaders
    };

    let retries = 0;
    while (retries < 10) {
      retries++;
      const token = await auth?.currentUser?.getIdToken(true);
        if (token) {
          headers.Authorization = `Bearer ${token}`;
          break;
        } else {
          await new Promise(resolve => setTimeout(resolve, 200));
        }
    } 
    let response;
    switch (mode) {
      case "post":
        response = await axios.post(`${config.serverAPI}/${url}`, payload, { headers });
        break;
      case "put":
        response = await axios.put(`${config.serverAPI}/${url}`, payload, { headers });
        break;
      case "patch":
        response = await axios.patch(`${config.serverAPI}/${url}`, payload, { headers });
        break;
      case "delete":
        response = await axios.delete(`${config.serverAPI}/${url}`, { headers });
        break;
      case "get":
      default:
        response = await axios.get(`${config.serverAPI}/${url}`, { headers });
    }

    return response?.data;
  } catch (error) {
    if (error?.response?.status === 401 && error?.response?.data?.message === 'Unauthorized') {
      window.location = '/home';
    }
    return { error };
  }
};

export default callApi;