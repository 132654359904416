import React, { useContext } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { AppContext } from './context/GlobalState';
import { Login, Register, Home, Profile, Claim, Item, Collection } from './pages';

const protectedRoutes = [
	{
		path: '/profile',
		main: <Profile />
	}
];

const App = () => {
	const { isLoggedIn } = useContext(AppContext);

	return (
		<BrowserRouter>
			<Routes>
				<Route exact path='/' element={<Navigate replace to='/home' />} />
				{protectedRoutes.map(route =>
					isLoggedIn ? (
						<Route key={route.path} path={route.path} element={route.main} />
					) : (
						<Route exact key={route.path} path={route.path} element={<Register />} />
					)
				)}
				<Route exact path='/home' element={<Home />} />
				<Route path='/register' element={<Register />} />
				<Route path='/login' element={<Login />} />
				<Route path='/claim' element={<Claim />} />
				<Route path='/collection/:collectionId' element={<Collection />} />
				<Route path='/nft/:collectionId/:itemId' element={<Item />} />
			</Routes>
		</BrowserRouter>
	);
};

export default App;
