import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Avatar, Image } from 'antd';
import { dateOptions } from '../utils/helpers';
import { missingImage } from '.';

const CampaignBox = ({ collection, ownedItems }) => {
	const navigate = useNavigate();

	return (
		<div className='campaign-box-wrapper'>
			<div className='campaign-header-wrapper'>
				<Space
					onClick={() =>
						navigate(`/collection/${collection?.collectionId}`, {
							state: collection
						})
					}
					className='collection-info-wrapper'
					size='middle'>
					<Avatar src={collection?.collectionLogoUrl} size={60} />
					<Space size={3} direction='vertical'>
						<span className='collection-name bold'>{collection?.collectionName}</span>
						<div className='client-name bold'>by {collection?.clientName}</div>
					</Space>
				</Space>
				<Space className='info-sub' size='middle'>
					<button className='primary-btn disabled-btn'>Redeem</button>
					<div className='date-wrapper'>
						{new Date(Number(collection?.endDate)) > new Date() ? (
							<Space>
								<span className='info'>Ending</span>
								<span className='date'>
									{new Date(Number(collection?.endDate)).toLocaleString(
										'en-us',
										dateOptions
									)}
								</span>
							</Space>
						) : (
							<span className='info'>Ended</span>
						)}
					</div>
					<div className='collected-wrapper'>
						{collection?.items?.filter(item => item?.isClaimed)?.length}/
						{collection?.items?.length} collected
					</div>
				</Space>
			</div>
			<div className='content-wrapper'>
				{collection?.items?.map(item => (
					<div
						key={item?.itemId}
						className={item?.isClaimed ? 'item-wrapper-claimed' : 'item-wrapper'}>
						<span className='item-name'>{item?.itemName}</span>
						<Image src={item?.imageUrl} alt={item?.itemName} fallback={missingImage} />
					</div>
				))}
			</div>
		</div>
	);
};

export default CampaignBox;
