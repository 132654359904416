import React from 'react';
import { Space, Avatar, Image } from 'antd';
import { missingImage } from '.';

const NftCard = ({ imageUrl, collectionName, collectionLogoUrl, itemName, claimed }) => (
	<div className='nft-card'>
		<div className={claimed ? 'card-wrapper-claimed' : 'card-wrapper'}>
			<div className='img-wrapper'>
				<Image src={imageUrl} alt={itemName} fallback={missingImage} />
			</div>
			<div className='info-wrapper'>
				<Space size={15} direction='vertical'>
					<span className='item-name bold'>
						{claimed ? itemName + ' (Claimed)' : itemName}
					</span>
					<Space size={5}>
						<Avatar src={collectionLogoUrl} size={22} />
						<span className='campaign-name'>{collectionName}</span>
					</Space>
				</Space>
			</div>
		</div>
	</div>
);

export default NftCard;
