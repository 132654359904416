import React from 'react';
import { Navbar, Footer } from '.';

const Layout = ({ children, search, callback, login }) => (
	<div className='page-wrapper'>
		<div className='main-section'>
			<Navbar search={search} callback={callback} login={login} />
			<div className='content'>{children}</div>
			{!login ? <Footer /> : null}
		</div>
	</div>
);

export default Layout;
