import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Avatar } from 'antd';
import { dateOptions } from '../utils/helpers';

const CampaignCards = props => {
	const navigate = useNavigate();

	return (
		<div className='card'>
			{props?.collections?.map(collection => (
				<div
					onClick={() =>
						navigate(`/collection/${collection?.collectionId}`, {
							state: collection
						})
					}
					className='card-wrapper'
					key={collection?.collectionId}>
					<div className='img-wrapper'>
						<img alt='campaign-card' src={collection?.collectionBannerUrl} />
					</div>
					<Avatar
						className='campaign-avatar'
						src={collection?.collectionLogoUrl}
						size={props?.avatarSize ?? 60}
					/>
					<div className='info-wrapper'>
						<Space size={props?.infoSize ?? 3} direction='vertical' align='center'>
							<span className='collection-name bold'>{collection?.collectionName}</span>
							<div className='client-name'>
								<span>
									by{` ${collection?.clientName}`}
								</span>
							</div>
						</Space>
					</div>
					<div className='date-wrapper'>
						<Space align='center'>
							{
								Number(collection?.endDate) >= Date.now() ? (
									<Space>
										<span className='info'>Ending</span>
										<span className='date'>
											{ new Date(Number(collection?.endDate)).toLocaleString('en-us', dateOptions) }
										</span>
									</Space>
								) : (
									<span className='info'>Ended</span>
								)
							}
						</Space>
					</div>
				</div>
			))}
		</div>
	);
};

export default CampaignCards;
