import React, { useState, useRef, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Input, Avatar, notification } from 'antd';
import callApi from '../utils/callApi';
import { Layout, NFTBox } from '../components';

const ClaimItem = () => {
	const navigate = useNavigate();
	const refInput = useRef(null);
	const [loading, setLoading] = useState(false);
	const [value, setValue] = useState('');
	const [item, setItem] = useState({});

	const fetchItem = async () => {
		setLoading(true);
		const response = await callApi('post', 'public', { referenceCode: value });

		if (!response?.error && response?.status !== 'error') {
			response?.payload && setItem(response?.payload);
			await localStorage.setItem("claim", JSON.stringify(response?.payload));
		} else {
			notification['error']({
				duration: 5,
				message:
					'Invalid Reference code. Please check your Reference code and try again!',
				description: response?.message
			});
		}
		setLoading(false);
	};

	const viewItem = () => {
		navigate(`/nft/${item?.collectionId}/${item?.itemId}`, { state: item, replace: true });
	};

	return (
		<Layout>
			<div className='claim-nft-wrapper'>
				{loading ? (
					<div className='loader'>
						<svg viewBox='0 0 160 160'>
							<rect x='16' y='16' width='128' height='128'></rect>
						</svg>
					</div>
				) : Object.keys(item)?.length ? (
					<Fragment>
						<div className='title bold'>This NFT is Super Rare!</div>
						<div className='nft-wrapper-visible'>
							<div className='info-wrapper'>
								<Space size='middle' align='center'>
									<Avatar src={item?.collectionLogoUrl} size={38} />
									<Space size={2} direction='vertical' align='start'>
										<span className='title bold'>{`NFT ${item?.itemName}`}</span>
										<span className='description'>{`Collection ${item?.collectionName} by ${item?.clientName}`}</span>
									</Space>
								</Space>
							</div>
							<img alt='nft-box' src={item?.imageUrl} />
						</div>
						<div className='description'>
							<strong>Congratulations to your new NFT!</strong> What now? View and save
							the NFT to your account to start collecting and accessing all the benefits.
						</div>
						<button onClick={() => viewItem()} className='primary-btn'>
							View NFT
						</button>
						<span className='note'>
							You can always access this NFT with your reference or qr code.
						</span>
					</Fragment>
				) : (
					<Fragment>
						<div className='title bold'>Claim your NFT</div>
						<div className='nft-wrapper'>
							<img alt='nft-box' src={NFTBox} />
						</div>
						<Space size='middle' direction='vertical' className='input-wrapper'>
							<span className='label bold'>Insert NFT Reference Code</span>
							<Input
								ref={refInput}
								size='large'
								placeholder='E.g. "Collection ID/Item ID/Claim Code" => 3UD9DN/NGTFFP/claim123'
								onChange={e => setValue(e.target.value)}
								onPressEnter={() => fetchItem()}
							/>
						</Space>
						<button onClick={() => fetchItem()} className='primary-btn'>
							Claim NFT
						</button>
					</Fragment>
				)}
			</div>
		</Layout>
	);
};

export default ClaimItem;
