import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input } from 'antd';
import { Loading } from '.';
import { AppContext } from '../context/GlobalState';
import { signInWithCredentials } from '../utils/firebase';

const LoginForm = () => {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const { setErrors, handleSignUp } = useContext(AppContext);

	const onLogin = async values => {
		try {
			setLoading(true);
			signInWithCredentials(
				'signIn',
				{ email: values.email, password: values.password },
				onFinishCallback,
				setLoginErrors
			);
			const claimedItem = await JSON.parse(localStorage.getItem('claim'));
			claimedItem
				? navigate(`/nft/${claimedItem.collectionId}/${claimedItem.itemId}`, {
						state: claimedItem
				  })
				: navigate('/home');
		} catch (error) {
			console.error(error.message);
		}
	};

	const onFinishCallback = async result => {
		await handleSignUp(result);
	};

	const setLoginErrors = errorObj => {
		if (typeof errorObj === 'string') {
			setErrors([errorObj]);
		} else if (typeof errorObj === 'object' && errorObj?.message) {
			setErrors([errorObj?.message]);
			console.log('Login err1', errorObj?.message);
		} else if (errorObj?.errorFields?.length) {
			const loginErrors =
				errorObj?.errorFields?.map(({ errors }) => errors[0].replace('!', '.')) || [];
			setErrors(loginErrors);
			console.log('Login err2', loginErrors);
		}
	};

	return (
		<div className='login-wrapper'>
			{loading ? (
				<Loading />
			) : (
				<div className='login-box'>
					<span className='header'>Sign in</span>
					<Form
						form={form}
						size='large'
						layout='vertical'
						name='login-form'
						hideRequiredMark
						onFinish={onLogin}
						autoComplete='off'
						onKeyDown={e => (e.key === 'Enter' ? e.preventDefault() : '')}
						validateTrigger='onSubmit'>
						<Form.Item
							name='email'
							label='Email'
							required
							hasFeedback
							rules={[
								{
									required: true,
									message: 'Please input your email!'
								}
							]}>
							<Input />
						</Form.Item>
						<Form.Item
							name='password'
							label='Password'
							required
							hasFeedback
							rules={[
								{
									required: true,
									message: 'Please input your password!'
								},
								{
									min: 8,
									max: 32,
									message: 'Password should have at least 8 characters'
								}
							]}>
							<Input.Password />
						</Form.Item>
						<button className='primary-btn' type='submit'>
							Sign in
						</button>
					</Form>
				</div>
			)}
		</div>
	);
};

export default LoginForm;
