import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import { AppContext } from '../context/GlobalState';
import { Layout, CampaignCards } from '../components';

const Home = () => {
	const [searchQuery, setSearchQuery] = useState('');
	const [currentCollections, setCurrentCollections] = useState([]);
	const [upcommingCollections, setUpcommingCollections] = useState([]);
	const { collections } = useContext(AppContext);

	useEffect(() => {
		let filteredCollections = collections;
		if (searchQuery) {
			filteredCollections = collections?.filter(collection =>
				collection?.collectionName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
				collection?.collectionId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
				collection?.collectionDescription?.toLowerCase().includes(searchQuery.toLowerCase()) ||
				collection?.collectionType?.toLowerCase().includes(searchQuery.toLowerCase()) ||
				collection?.clientName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
				collection?.items?.filter(item => 
					item?.itemName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
					item?.itemDescription?.toLowerCase().includes(searchQuery.toLowerCase()) ||
					item?.itemId?.toLowerCase().includes(searchQuery.toLowerCase())
				)?.length
			);
		}

		setCurrentCollections(filteredCollections?.filter(collection =>
			Number(collection?.startDate) <= Date.now()
		));
		setUpcommingCollections(filteredCollections?.filter(collection =>
			Number(collection?.startDate) > Date.now()
		));
	}, [collections, searchQuery]);

	return (
		<Layout search callback={value => setSearchQuery(value)}>
			<div className='sub-header'>
				<Space size={40} direction='vertical' className='content'>
					<span className='title bold'>Claim your NFT and enjoy the rewards!</span>
					<span className='description'>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sagittis, quis
						fames donec mi.
					</span>
					<Space size='middle'>
						<Link to={'/claim'}>
							<button className='primary-btn'>Claim NFT</button>
						</Link>
					</Space>
				</Space>
				<CampaignCards
					collections={collections.slice(0, 1)}
					avatarSize={92}
					infoSize={10}
				/>
			</div>
			<div className='campaign-wrapper'>
				{
					currentCollections?.length ? (
						<div className='cards-wrapper'>
							<div className='title bold'>Latest Collections</div>
							<div className='scrolling-wrapper'>
								<CampaignCards collections={currentCollections} />
							</div>
						</div>
					) : null
				}
				{
					upcommingCollections?.length ? (
						<div className='cards-wrapper'>
							<div className='title bold'>Upcomming Campaigns</div>
							<div className='scrolling-wrapper'>
								<CampaignCards collections={upcommingCollections} />
							</div>
						</div>
					) : null
				}
			</div>
		</Layout>
	);
};

export default Home;
